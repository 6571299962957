<template>
  <div>
    <video v-if="previewData && !limitedPreview" width="100%" height="auto"
           controls>
      <source
          :type="mimeType"
          :src="previewData"
      >
      Your browser does not support the video tag.
    </video>
    <div v-else class="square-image lighter">
      <icon type="video" size="1.8" />
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
export default {
  name: "VideoPreview",
  components: {
    Icon,
  },
  props: {
    previewData: {type: String, default: ''},
    mimeType: {type: String, default: 'video/mp4'},
    limitedPreview: {type: Boolean, default: false},
  },
}
</script>

<style scoped>

</style>